@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;700&display=swap");

* {
  background: none;
  border: none;
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  line-height: 1.125;
  margin: 0;
  padding: 0;
}

.App {
  background: black;
  font-family: "Zen Maru Gothic", serif;
  font-weight: 400;
  font-style: normal;
  padding: 1rem;
  max-width: 1000px;
  margin: 0 auto;
  h1 {
    font-size: 4rem;
    font-weight: bold;
    color: #bbb;
    margin: 4rem 0;
  }
  small {
    font-size: 0.75em;
  }
  em {
    font-style: italic;
  }
  strong {
    font-size: 1.25em;
    font-weight: bold;
  }
  a {
    outline: none;
    font-weight: bold;
    border: 1px solid transparent;
    &:focus,
    &:hover {
      border: 1px solid white;
      color: white;
    }
  }
  .text-center {
    text-align: center;
  }
  .App-header {
    border-radius: 1rem;
    background: #0f0f0f;
    color: #aaa;
    align-items: center;
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    margin-bottom: 3rem;
    .App-logo {
      font-size: 1.5rem;
    }
    a {
      text-decoration: none;
    }
    .App-nav {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #0f0f0f;
        font-size: 1.25rem;
        background: #aaa;
        width: 25px;
        height: 25px;
        padding: 0.2rem;
        &:focus,
        &:hover {
          background: white;
        }
        &.circle {
          border-radius: 100%;
        }
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
  .DevLogs {
    display: flex;
    flex-wrap: wrap;
    .DevLog {
      flex: 0.5;
      border-radius: 1rem;
      background: #111;
      text-align: left;
      gap: 0;
      .thumbnail {
        a {
          display: flex;
        }
        a:focus,
        a:hover {
          img {
            border: 1px solid white;
            transform: rotate(0) scale(1);
          }
        }
        img {
          transform: rotate(-5deg) scale(1.25);
          transition: 0.25s all ease-in-out;
        }
      }
      .preview,
      .tags {
        padding: 1rem;
      }
      .preview {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        a {
          text-decoration: none;
        }
      }
      .meta {
        display: flex;
        justify-content: space-between;
      }
      .tags {
        background: black;
        border: 1px solid #111;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
        gap: 0.5rem;
      }
    }
  }
  .DevLog {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1.25em;
    .thumbnail {
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        min-height: 200px;
      }
    }
    p {
      margin-bottom: 2rem;
      font-style: normal;
    }
    .meta {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
    .tags {
      margin-top: 0.5rem;
      display: flex;
      gap: 0.5rem;
      .tag {
        background: #111;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem 0.5rem;
        font-size: 0.75em;
        a {
          align-items: center;
          display: flex;
          text-decoration: none;
          gap: 0.25rem;
        }
      }
    }
  }
  .App-footer {
    text-align: center;
    margin: 1rem 0;
    padding: 1rem;
  }
}
